<template>
  <div>
    <lp-datatable
      ref="CostCentreRef"
      :extra-parameter="extraParam"
      :title="`Cost Centre ${currentDateScope}`"
      store-module="settlement"
      store-data-endpoint="getAdminSettlementReportPayout"
      store-getter-endpoint="getSettlementReportPayoutList"
      pagination-state-key="settlementReportPayoutList"
      :features="loadedConfig.datatableFeatures"
      :load-system-constant="true"
      default-search-field="merchant_id"
      default-sort-field="created_at"
      :search-options-dropdown="loadedConfig.searchOptsDropdown"
      :conf="loadedConfig"
      @actionBtn-clicked="actionBtnClicked"
    />

    <b-modal
      ref="detailsDayModal"
      title="Details"
      size="lg"
    >
      <cost-centre-transaction-info-modal :row-data="selectedRow" />
    </b-modal>
  </div>

</template>

<script>
import { DateTime } from 'luxon'
import humaniseMixin from '@/common/humanise.mixin'
import Config from '@/views/administration/costCentre/payoutFormConfig'
import LpDatatable from '@/leanpay-components/LPDataTable.vue'
import CostCentreTransactionInfoModal
from '@/views/administration/costCentre/component/costCentreTransactionInfoModal.vue'

export default {
  components: { CostCentreTransactionInfoModal, LpDatatable },
  mixins: [humaniseMixin],
  data() {
    return {
      extraParam: {
        // eslint-disable-next-line no-nested-ternary
        filter_type: this.$route.params.mode === 'year' ? 3 : this.$route.params.mode === 'month' ? 2 : this.$route.params.mode === 'day' ? 1 : 3,
      },
      mode: null,
      currentDateScope: null,
      selectedRow: null,

    }
  },
  computed: {
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
    tableConfig() {
      if (Config) {
        return Config.tableData()
      }
      return {}
    },
  },
  async mounted() {
    // this.isBusy = false

    let startDate = '01-01-1900'
    let endDate = '01-01-2100'
    let filterType = 3
    this.currentDateScope = 'All'

    const breadcrumbUpdatePayload = [
      { title: 'Cost Centre' },
      { title: 'Payout', route: `/cost-centre/payout/${this.$route.params.type}` },
    ]

    if (this.$route.params.year) {
      startDate = `01-01-${this.$route.params.year}`
      endDate = `31-12-${this.$route.params.year}`
      this.currentDateScope = `(${this.$route.params.year})`
      breadcrumbUpdatePayload.push({ title: `${this.$route.params.year}`, route: `/cost-centre/${this.$route.params.type}/${this.$route.params.year}` })
      filterType = 3
      if (this.$route.params.month) {
        const date = DateTime.fromFormat(`01-${this.$route.params.month}-${this.$route.params.year}`, 'dd-LL-yyyy')
        const endOfMonth = date.endOf('month').toFormat('dd-LL-yyyy')
        startDate = `01-${this.$route.params.month}-${this.$route.params.year}`
        endDate = `${endOfMonth}`

        this.currentDateScope = `(${date.monthLong} ${this.$route.params.year})`
        breadcrumbUpdatePayload.push({ title: `${date.monthLong}`, route: `/cost-centre/${this.$route.params.type}/${this.$route.params.year}/${this.$route.params.month}` })
        filterType = 2
        if (this.$route.params.day) {
          startDate = `${this.$route.params.day}-${this.$route.params.month}-${this.$route.params.year}`
          endDate = `${this.$route.params.day}-${this.$route.params.month}-${this.$route.params.year}`
          this.currentDateScope = `(${this.$route.params.day} ${date.monthLong} ${this.$route.params.year})`
          breadcrumbUpdatePayload.push({ title: `${this.$route.params.day}` })
          filterType = 1
        }
      }
    }

    this.extraParam = {
      ...this.extraParam,
      filter_type: filterType,
      start_date: startDate,
      end_date: endDate,
    }
    this.$refs.CostCentreRef.initDataGrid()

    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
  },
  methods: {
    actionBtnClicked(e) {
      if (e.actionName === 'viewCostCentreDetail') {
        if (this.$route.params.day) {
          this.selectedRow = e.rowData
          this.$refs.detailsDayModal.show()
          return
        }
        const parameter = {
          year: 2024,
        }

        const date = DateTime.fromISO(e.rowData.created_at)
        const month = DateTime.fromISO(e.rowData.created_at).toFormat('LL')
        const day = DateTime.fromISO(e.rowData.created_at).toFormat('dd')
        const { year } = date
        console.log(date)

        if (!this.$route.params.year) {
          parameter.year = year
        }
        if (this.$route.params.year && !this.$route.params.month) {
          parameter.year = year
          parameter.month = month
        }
        if (this.$route.params.year && this.$route.params.month && !this.$route.params.day) {
          parameter.year = year
          parameter.month = month
          parameter.day = day
        }

        this.$router.push({ name: 'administration-cost-centre-payout-list', params: parameter }).catch(() => {})
      }
    },
    tnxTypeFilterBtnClicked(e) {
      const parameter = { }

      if (this.$route.params.year) {
        parameter.year = this.$route.params.year
      }
      if (this.$route.params.month) {
        parameter.month = this.$route.params.month
      }
      if (this.$route.params.day) {
        parameter.day = this.$route.params.day
      }

      if (e === 'allTxnFilter') {
        this.extraParam.transaction_type = []
        // txnType = 'all'
        this.$refs.CostCentreRef.initDataGrid()
        this.$router.push({ name: 'administration-cost-centre-list', params: { type: 'all', ...parameter } }).catch(() => {})
      }
      if (e === 'normalTxnFilter') {
        this.extraParam.transaction_type = [1]
        //
        // txnType = 1
        this.$refs.CostCentreRef.initDataGrid()

        this.$router.push({ name: 'administration-cost-centre-list', params: { type: 'normal', ...parameter } }).catch(() => {})
      }
      if (e === 'prefundTxnFilter') {
        this.extraParam.transaction_type = [2]
        // txnType = 2
        this.$refs.CostCentreRef.initDataGrid()

        this.$router.push({ name: 'administration-cost-centre-list', params: { type: 'prefund', ...parameter } }).catch(() => {})
      }
      if (e === 'transactionTxnFilter') {
        this.extraParam.transaction_type = [3]
        // txnType = 3
        this.$refs.CostCentreRef.initDataGrid()
        this.$router.push({ name: 'administration-cost-centre-list', params: { type: 'transaction', ...parameter } }).catch(() => {})
      }
      if (e === 'billPaymentTxnFilter') {
        this.extraParam.transaction_type = [4]
        // txnType = 4
        // this.$refs.CostCentreRef.initDataGrid()
        this.$router.push({ name: 'administration-cost-centre-list', params: { type: 'billpayment', ...parameter } }).catch(() => {})
      }
      console.log('statusFilterBtnClicked', e)
    },
  },
}
</script>
