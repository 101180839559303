<template>
  <div>
    <pre>{{ rowData }}</pre>
  </div>

</template>

<script>

export default {
  components: { },
  mixins: [],
  props: {
    rowData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {

    }
  },
  computed: {

  },
  async mounted() {

  },
  methods: {

  },
}
</script>
